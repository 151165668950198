<template>
  <main class="main">
    <section class="container">
      <BreadcrumbsComponent :title="page ? page.title : 'Вакансии'" />
      <ul class="vacancy" v-if="vacancy && vacancy.length">
        <li class="vacancy__item" v-for="(v, i) in vacancy" :key="i">
          <a
            href="#"
            @click.prevent="setActiveVacancy(i)"
            class="vacancy__item-header"
            :class="{ 'vacancy__item-header--open': activeVacancy === i }"
          >
            <h3 class="vacancy__item-title">{{ v.title }}</h3>
            <span class="vacancy__item-subtitle">З/п: {{ v.wage }}</span>
            <span class="vacancy__item-subtitle">Город: {{ v.city }}</span>
            <ArrowUpIcon />
          </a>
          <div class="vacancy__item-description" v-if="activeVacancy === i">
            <EditorJSComponent :text="getVacancyText(v)" />
          </div>
        </li>
      </ul>
      <span class="vacancy__empty" v-else>Информация скоро появится</span>
    </section>
  </main>
</template>

<script>
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import ArrowUpIcon from "components/svg/ArrowUp.vue";
import EditorJSComponent from "components/EditorJSComponent.vue";
import { cityIn } from "lvovich";

export default {
  name: "VacancyList",
  data() {
    return {
      activeVacancy: 0,
    };
  },
  components: { EditorJSComponent, ArrowUpIcon, BreadcrumbsComponent },
  async asyncData({ store, link }) {
    store.state.alternateLink = link;
    await store.dispatch("GET_VACANCY_LIST_PAGE");
  },
  computed: {
    vacancy() {
      if (this.$store.state.vacancy_list_page && this.$store.state.vacancy_list_page.vacancy) {
        return this.$store.state.vacancy_list_page.vacancy;
      } else {
        return undefined;
      }
    },
    page() {
      let page;
      if (this.$store.state.vacancy_list_page && this.$store.state.vacancy_list_page.page) {
        page = this.$store.state.vacancy_list_page.page;
      }
      return page;
    },
  },
  methods: {
    getVacancyText(v) {
      if (v.text) {
        let haveError = false;
        try {
          JSON.parse(v.text);
        } catch (e) {
          haveError = true;
        }
        if (!haveError) {
          return JSON.parse(v.text);
        } else {
          return {
            blocks: [
              {
                type: "paragraph",
                data: { text: v.text },
              },
            ],
          };
        }
      }
      return {
        blocks: [],
      };
    },
    setActiveVacancy(index) {
      if (this.activeVacancy === index) {
        this.activeVacancy = null;
        return;
      }
      this.activeVacancy = index;
    },
    getImg(item) {
      if (item.head_img && item.head_img.img && item.head_img.img.url) {
        return this.$store.state.api + item.head_img.img.url;
      }
    },
  },
  metaInfo() {
    let api = this.$store.state.api;
    let metaName = this.page.title;
    let metaFullPath = this.$route.path;
    let alternateLink = this.$store.state.alternateLink;
    let data = {};
    let city = this.$store.state.cities.find((c) => c.title_eng === this.$store.state.geoData.city);
    city = cityIn(city ? city.title : "Казань");
    if (this.$store.state.vacancy_list_page && this.$store.state.vacancy_list_page.page) {
      data = this.$store.state.vacancy_list_page.page;
    }
    let img = this.getImg(data) || "";
    const title = (data.meta_title || data.title).replace(/{{ city }}/g, `в ${city}`);
    return {
      title,
      script: [
        {
          type: "application/ld+json",
          json: {
            "@context": "https://schema.org",
            "@type": "WebPage",
            name: title,
            description: data.meta_description.replace(/{{ city }}/g, `в {city}`),
          },
        },
        {
          type: "application/ld+json",
          json: {
            "@context": "http://www.schema.org",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: "1",
                name: "Главная",
                item: api,
              },
              {
                "@type": "ListItem",
                position: "2",
                name: metaName,
                item: api + metaFullPath,
              },
            ],
          },
        },
      ],
      link: [
        { rel: "canonical", href: alternateLink },
        { rel: "alternate", hreflang: "x-default", href: alternateLink },
      ],
      meta: [
        {
          vmid: "keywords",
          name: "keywords",
          content: (data.meta_keywords || "").replace(/{{ city }}/g, `в ${city}`),
        },
        {
          vmid: "description",
          name: "description",
          content: (data.meta_keywords || "").replace(/{{ city }}/g, `в ${city}`),
        },
        {
          vmid: "og:title",
          name: "og:title",
          content: title,
        },
        {
          vmid: "og:image",
          name: "og:image",
          content: img,
        },
        {
          vmid: "og:description",
          name: "og:description",
          content: (data.meta_keywords || "").replace(/{{ city }}/g, `в ${city}`),
        },
      ],
    };
  },
};
</script>

<style lang="stylus">
@import "~@/styles/global/global.styl"
@import "~@/styles/parts/vacancy.styl"
</style>
